(angular => {
  "use strict";

  angular.module("Participant").controller("Participant.ExportModalCtrl", [
    "$mdDialog",
    "Toast",
    "ParticipantService",
    "AuthService",
    "participants",
    "type",
    ExportModalCtrl
  ]);

  function ExportModalCtrl($mdDialog, Toast, ParticipantService, authService, participants, type) {
    this.user = authService.getTokenData();
    this.fields = [
      { "key": "name", "label": "Nome", required: true },
      { "key": "credential_name", "label": "Nome na Credencial" },
      { "key": "birth_date", "label": "Data de Nascimento" },
      { "key": "cpf", "label": "CPF" },
      { "key": "position", "label": "Cargo" },
      { "key": "company_id", "label": "Empresa" },
      { "key": "table", "label": "Mesa" },
      { "key": "position_type_id", "label": "Tipo de cargo"},
      { "key": "guest_company", "label": "Empresa Convidada" },
      { "key": "status", "label": "Situação do Convite" },
      { "key": "enterprise_phone", "label": "Telefone Comercial" },
      { "key": "home_phone", "label": "Telefone Residencial" },
      { "key": "personal_phone", "label": "Telefone Celular" },
      { "key": "personal_mail", "label": "Email Pessoal" },
      { "key": "enterprise_mail", "label": "Email Comercial" },
      { "key": "register", "label": "Matrícula" },
      { "key": "gender", "label": "Gênero" },
      { "key": "observation", "label": "Observação" },
      { "key": "credential_color", "label": "Crachá" },
      { "key": "classification_id", "label": "Classificação"},
      { "key": "foreign_document", "label": "Documento Estrangeiro"},
      { "key": "decline_event_terms_date", "label": "Recusa dos Termos do Evento"},
    ];

    if (this.user.role === 'organizer') {
      this.fields = [
        { "key": "name", "label": "Nome", required: true },
        { "key": "credential_name", "label": "Nome na Credencial" },
        { "key": "position", "label": "Cargo" },
        { "key": "company", "label": "Empresa" },
        { "key": "table", "label": "Mesa" },
        { "key": "guest_company", "label": "Empresa Convidada" }
      ];
    }
    if (type === 'speaker') {
      this.fields = [
        { "key": "name", "label": "Nome", required: true },
        { "key": "credential_name", "label": "Nome na Credencial" },
        { "key": "position", "label": "Cargo" },
        { "key": "cpf", "label": "CPF" }
      ];
    }
    this.selected = this.fields.filter(x => x.required);

    this.export = () => {
      if (!this.selected.length) {
        return Toast.show("Seleciona ao menos um campo", "error");
      }

      ParticipantService.exportList(this.selected, participants, type);
      $mdDialog.hide();
    };

    this.cancel = $mdDialog.cancel;

  }
})(angular || {});
