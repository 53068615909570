(angular => {
  "use strict";

  angular.module("Participant").controller("Participant.ImportModalCtrl", [
    "$timeout",
    "$mdDialog",
    "Toast",
    "Loader",
    "$filter",
    "$window",
    "EventService",
    "ParticipantService",
    "participants",
    "participantType",
    ImportModalCtrl
  ]);

  function validateCPF(cpf) {
    // Remove non-numeric characters
    cpf = cpf.replace(/[^\d]/g, "");

    // Validate length and exclude invalid CPFs like "000.000.000-00"
    if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) {
      return false;
    }

    // Helper function to calculate check digit
    const calculateCheckDigit = (cpfSlice, factor) => {
      let sum = 0;
      for (let i = 0; i < cpfSlice.length; i++) {
       sum += parseInt(cpfSlice[i]) * factor--;
      }
      const remainder = sum % 11;
      return remainder < 2 ? 0 : 11 - remainder;
    };

    // Validate first check digit
    const firstCheckDigit = calculateCheckDigit(cpf.slice(0, 9), 10);
    if (firstCheckDigit !== parseInt(cpf[9])) {
      return false;
    }

    // Validate second check digit
    const secondCheckDigit = calculateCheckDigit(cpf.slice(0, 10), 11);
    if (secondCheckDigit !== parseInt(cpf[10])) {
      return false;
    }

    return true;
  }


  function ImportModalCtrl($timeout, $mdDialog, Toast, Loader, $filter, $window, eventService, participantService, participants, participantType) {
    let event;

    this.error = false;
    this.query = { page: 1, limit: 10 };
    this.participantType = participantType;
    this.participants = participants.map(p => {
      Object.keys(p).forEach(key => {
        if (typeof p[key] !== 'string') return;
        p[key] = (p[key] || '').toString().trim();
      });

      p.type = participantType;
      let cpf = (p.cpf || '').toString().replace(/\D/g, "");
      cpf = cpf.padStart(11, "0");
      if(validateCPF(cpf)){
        p.cpf = cpf;
      } else {
        // p.cpf is probably a "foreign document"
        p.foreign_document = true;
      }

      if (typeof p.birth_date === 'string') {
        let [day, month, year] = p.birth_date.toString().split('/');
        year = year.length === 2 ? `19${year}` : year;

        p.birth_date = new Date(year, Number(month) - 1, day);
      }

      return p;
    });

    eventService.current().then(eventData => event = eventData);

    this.save = () => {
      if (moment() > moment(event.limit_date) && !event.online) {
        return $mdDialog.show(
          $mdDialog.alert()
          .title("A data para o cadastro de novos participantes expirou ")
          .ok("Ok")
        );
      }

      return Loader.add(participantService.importList(this.participants)).then((result) => {
        Toast.show("Participantes importados com sucesso", "success");

        if (result.errors.length === 0) {
          $mdDialog.hide();
          return;
        }

        this.error = true;
        this.query.page = 1;
        this.successCount = this.participants.length - result.errors.length;
        this.participants = result.errors.map(data => {
          data.participant.error = data.error;
          return data.participant;
        });

      }).catch(err => Toast.httpHandler(err));
    };

    this.cancel = $mdDialog.cancel;
    this.close = $mdDialog.hide;
  }
})(angular || {});
